<template>
  <router-view></router-view>
</template>

<script>

export default {
  components: {
  },
  mounted() {
    console.log("进入cashier页面");
  }

}
</script>
